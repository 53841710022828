// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alarm-js": () => import("./../src/pages/alarm.js" /* webpackChunkName: "component---src-pages-alarm-js" */),
  "component---src-pages-chit-chat-js": () => import("./../src/pages/chit-chat.js" /* webpackChunkName: "component---src-pages-chit-chat-js" */),
  "component---src-pages-from-your-device-js": () => import("./../src/pages/from-your-device.js" /* webpackChunkName: "component---src-pages-from-your-device-js" */),
  "component---src-pages-garden-of-pots-js": () => import("./../src/pages/garden-of-pots.js" /* webpackChunkName: "component---src-pages-garden-of-pots-js" */),
  "component---src-pages-homebodies-js": () => import("./../src/pages/homebodies.js" /* webpackChunkName: "component---src-pages-homebodies-js" */),
  "component---src-pages-how-wide-is-the-average-fingernail-js": () => import("./../src/pages/how-wide-is-the-average-fingernail.js" /* webpackChunkName: "component---src-pages-how-wide-is-the-average-fingernail-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-j-s-story-js": () => import("./../src/pages/j__s-story.js" /* webpackChunkName: "component---src-pages-j-s-story-js" */),
  "component---src-pages-new-video-js": () => import("./../src/pages/new-video.js" /* webpackChunkName: "component---src-pages-new-video-js" */),
  "component---src-pages-play-feeling-good-music-js": () => import("./../src/pages/play-feeling-good-music.js" /* webpackChunkName: "component---src-pages-play-feeling-good-music-js" */),
  "component---src-pages-play-the-earth-js": () => import("./../src/pages/play-the-earth.js" /* webpackChunkName: "component---src-pages-play-the-earth-js" */),
  "component---src-pages-training-session-js": () => import("./../src/pages/training-session.js" /* webpackChunkName: "component---src-pages-training-session-js" */),
  "component---src-pages-ursonate-js": () => import("./../src/pages/ursonate.js" /* webpackChunkName: "component---src-pages-ursonate-js" */),
  "component---src-pages-ursonate-performance-js": () => import("./../src/pages/ursonate-performance.js" /* webpackChunkName: "component---src-pages-ursonate-performance-js" */),
  "component---src-pages-your-hair-is-very-wonderful-js": () => import("./../src/pages/your-hair-is-very-wonderful.js" /* webpackChunkName: "component---src-pages-your-hair-is-very-wonderful-js" */)
}

